@import "./Mixins.scss";

.footer-wrapper {
    text-align: center;
    background-color: #EEF2F8;
    padding: 80px 0 0;

    a{
        color: #5B6575;
        :hover{
            color:#55E6C3 !important;
        }
    }

    &__item{
        margin-top: 30px;
        margin-bottom: 55px;
        p {
            font-family: 'Gilroy-Medium';
        }
    }
    &__flex li p {
        color: #5B6575!important;
    }

    .icon-footer{
        margin-right: 10px !important;
    }

    h2{
        font-family: 'Gilroy-Bold';
        font-size: 16px;
        line-height: 20px;
        color: #5B6575 !important;
        margin-bottom: 23px;
    }

    &__flexs {
        display: block;
        text-align: center;
        justify-content: center;
        gap: 25px;

        li {
            margin-bottom: 45px;
            p {
                color: #5B6575;
            }
        }
        
        @include media(md) {
            display: flex;
        }
        @include media(lg) {
            display: flex;
        }
        @include media(xl) {
            display: flex;
        }

        img {
            margin-bottom: 5px;
            @include media(md) {
                margin: 5px;
            }
            @include media(lg) {
                margin: 0;
            }
            @include media(xl) {
                margin: 0;
            }
        }
    }
    
    &__flex {
        display: block;
        text-align: center;
        justify-content: center;
        gap: 40px;
        margin-bottom: 40px;

        @include media(md) {
            display: flex;
        }
        @include media(lg) {
            display: flex;
        }
        @include media(xl) {
            display: flex;
        }

        li {
            a {
                font-family: 'Gilroy-Regular';
                font-size: 16px;
                line-height: 19px;
                color: #5B6575;
            }

            p {
                color: #5B6575 !important;
                margin: 0; 
            }
        }
    }

    &__menu{
        display: flex;
        flex-direction: column;
        padding: 10%;
        align-items: space-around;
        text-align: left;
       &:nth-child(2),  &:nth-child(4){
            text-align: left;
            @include media(md) {text-align: center;}
            @include media(lg) { text-align: left;}
            @include media(xl) {text-align: left;}
        }
        @include media(md) {
            text-align: center;
            padding: 0;
        }

        @include media(lg) {
            text-align: left;
            padding-left: 5%;
        }

        @include media(xl) {
            text-align: left;
            padding-left: 5%;
        }

        li{
            padding-bottom: 16px;
        }
    }

    &__logos{
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 992px) {
            justify-content: center;
            margin-top: 20px;
        }
    }

    &__info {
        text-align: center;
        background: #5B6575;
        color: #FFFFFF;
        padding: 20px 30px;

        @include media(md) {
            padding: 52px 65px;
        }
        
        &-1{
            text-align: left;
            font-family: 'Gilroy-Regular';
            @media screen and (max-width: 992px) {
                margin-top: 20px;
            }
            img{
                margin-bottom: 20px;
            }

            p{
                color: #FFFFFF !important;
                font-size: 13px;
                text-align: left !important;
            }

            a{
                color: white;
                text-decoration: none;
                :hover{
                    color: white;
                    text-decoration: none;
                }
            }
        }

        &-logos {
            display: flex;
            align-items: center;
            justify-content: center;

            @media screen and (min-width: 992px) {
                justify-content: end;
                margin-top: 10px;
            }
        }

        &-2{
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 40px;
            text-align: end;
            @media screen and (max-width: 992px) {
                margin-top: 20px;
                text-align: start;
            }
            // margin: 43px 0;

            @include media(md) {
                display: block;
            }
            @include media(sm) {
                display: block;
            }
            @include media(sm) {
                display: block;
            }

            a{
                font-size: 13px;
                font-family: 'Gilroy-Medium';
                color: white;
                text-decoration: none;

                :hover{
                    color: white;
                    text-decoration: none;

                }
            }

            ul li {
                margin-left: 20px;
            }
        }

        &-3{

            display: flex;
            align-items: center;
            justify-content: space-between;
            text-align: left;
            
            @include media(md) {
                display: block;
                text-align: center;
            }
            @include media(sm) {
                display: block;
                text-align: center;
            }
            @include media(sm) {
                display: block;
                text-align: center;
            }

            a{
                text-decoration: underline;
                color:#FFFFFF;
                margin-top: 20px;
            }
        }
    }
}
.footer__menu:hover{
    color:#55E6C3 ;
    transition: 0.3s;
}
.ipab-col {
    display: block;

    @include media(md) {
        display: none;
    }

    @include media(lg) {
        display: none;
    }

    @include media(xl) {
        display: none;
    }
}

.ipab-img {
    display: none;

    @include media(md) {
        display: inline;
    }

    @include media(lg) {
        display: inline;
    }

    @include media(xl) {
        display: inline;
    }
}
