.container {
    width: 90%;
    margin: auto;
    border: 2px solid #235A4C;
    border-radius: 10px;
    padding: 20px;
}

.title {
    font-family: 'Gilroy-Bold', sans-serif;
}

.subtitle {
    font-family: 'Gilroy-Regular', sans-serif;
    font-weight: 600;
}

.group {
    margin-bottom: 2em;
    border: 1px solid #9CB5AE;
    border-radius: 5px;
}

.group-title {
    background-color: #9CB5AE;
    padding: 5px;
}

.group-width {
    width: 100%;
}

.footer-p {
    font-size: 12px;
}

.divider {
    width: 100%;
    border: 1px solid #235A4C;
    margin: 10px 0;
}

.schedule {
    border: 1px solid #9CB5AE;
    border-radius: 5px;
    margin-bottom: 1em;
    background-color: #9CB5AE;
}

.une h1 {
    font-size: 40px;
    font-style: italic;
}

.une img {
    width: 5em;
}

@media (min-width: 768px) {
    .container {
        width: 70%;
    }

    .group-width {
        width: 47%;
    }

    .une h1 {
        font-size: 3rem;
    }
}

@media (min-width: 992px) {
    .container {
       width: 60%;
    }

    .group-width {
        width: 47%;
    }

    .une h1 {
        font-size: 4rem;
    }
}
 
@media (min-width: 1200px) {
    .container {
       width: 50%;
    }

    .group-width {
        width: 47%;
    }

    .une h1 {
        font-size: 4rem;
    }
}

@media (min-width: 1900px) {
    .container {
       width: 45%;
    }

    .group-width {
        width: 47%;
    }

    .une h1 {
        font-size: 4.5rem;
    }
}
