@import "./Fonts.scss";
@import "./Mixins.scss";

body.persons,
#root.persons {
	@include backgroundBody(white);
}

.persons-layout {
    h1 {
        color: #55E6C3;
    }

    h2, h3 {
        color: #000000;
    }

    h1, h2 {
        font-family: 'Gilroy-Bold', sans-serif;   
    }

    h3 {
        font-family: 'Gilroy-Light', sans-serif; 
    }

    .store-button.img-web {
        display: none;

        @include media(md) {
            display: inline-block;
        }

        @include media(lg) {
            display: inline-block;
        }
        
        @include media(xl) {
            display: inline-block;
        }

        @include media(xxl) {
            display: inline-block;
        }
    }

    .store-button.img-mobile {
        display: inline-block;

        @include media(md) {
            display: none;
        }

        @include media(lg) {
            display: none;
        }
        
        @include media(xl) {
            display: none;
        }

        @include media(xxl) {
            display: none;
        }
    }

    .store-button img {
        width: 80px;
        margin: 0 15px 10px 15px;

        @include media(md) {
            width: 200px;
            margin: 0 0 10px 0;
        }

        @include media(lg) {
            width: 180px;
            margin-bottom: 0;
            margin-right: 15px; 
        }
        
        @include media(xl) {
            width: 180px;
            margin-bottom: 0;
            margin-right: 15px;          
        }

        @include media(xxl) {
            width: 180px;
            margin-bottom: 0;
            margin-right: 15px;     
        }
    }

    .hero-col {
        text-align: center;
        display: flex;
        flex-direction: column; 
        justify-content: center;
        align-items: center;

        @include media(md) {
            align-items: flex-start;
            margin: 0;
            text-align: left;
        }

        @include media(lg) {
            align-items: flex-start;
            margin: 0;
            text-align: left;    
        }
        
        @include media(xl) {
            align-items: flex-start;
            margin: 0;
            text-align: left;           
        }

        @include media(xxl) {
            align-items: flex-start; 
            margin: 0;
            text-align: left;           
        }
    }

    .suscriptions {
        width: 100%;
        background-image: url("../images/personas/personal_pin_verde.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;

        .container {
            display: flex;
            flex-direction: column; 
            justify-content: center;
        }
    }

    .suscriptions-col {
        display: flex;
        flex-direction: column; 
        justify-content: center;
        align-items: center;
        text-align: center;
    
        @include media(md) {
            align-items: flex-start;
            text-align: left;
        }

        @include media(lg) {
            align-items: flex-start;
            text-align: left;            
        }
        
        @include media(xl) {
            align-items: flex-start;
            text-align: left;            
        }

        @include media(xxl) {
            align-items: flex-start;
            text-align: left;            
        }
    }

    .app-green {
        background-color: #55E6C3;
    }

    .hero-col,
    .suscriptions-col,
    .app-green {
        h1 {
            font-size: 40px;

            @include media(md) {
                font-size: 3rem;
            }
    
            @include media(lg) {
                font-size: 4rem;          
            }
            
            @include media(xl) {
                font-size: 4rem;           
            }
    
            @include media(xxl) {
                font-size: 4.5rem;           
            }
        }

        h2 {
            font-size: 24px;
            font-family: 'Gilroy-Bold';

            @include media(md) {
                font-size: 1.5rem;
            }
    
            @include media(lg) {
                font-size: 2rem;          
            }
            
            @include media(xl) {
                font-size: 2rem;           
            }
    
            @include media(xxl) {
                font-size: 2.5rem;           
            }
        }

        h3 {
            font-size: 28px;
        }

        p {
            font-size: 17px;

            @include media(md) {
                font-size: 22px;
            }
    
            @include media(lg) {
                font-size: 1.4rem;          
            }
            
            @include media(xl) {
                font-size: 1.5rem;           
            }
    
            @include media(xxl) {
                font-size: 1.5rem;           
            }
        }
    }
}

.icon-text-person {
    background-color: #FFFFFF !important;
    box-shadow: 0px 0px 50px rgba(201, 210, 226, 0.5);
    img {
        margin-bottom: 20px;
    }
    &__wrapper{

        h2, h3 {
            color: #000000;
            font-size: 30px;
            margin-top: 10px;
        }

        p{
            color: #000000;
            font-size: 20px;
            font-family: "Gilroy-Light";
            margin-top: 10px;
        }

        &:nth-child(2) {            
            border-left: none;
            border-right: none;
            border-top: 1px solid #5B6575;
            border-bottom: 1px solid #5B6575;
            
            @include media(sm) {
                border-left: none;
                border-right: none;
                border-top: 1px solid #5B6575;
                border-bottom: 1px solid #5B6575;
            }
            
            @include media(md) {
                border-left: none;
                border-right: none;
                border-top: 1px solid #5B6575;
                border-bottom: 1px solid #5B6575;
            }
            
            @include media(lg) {
                border-left: 1px solid #5B6575;
                border-right: 1px solid #5B6575;
                border-top: none;
                border-bottom: none;   
            }

            @include media(xl) {
                border-left: 1px solid #5B6575;
                border-right: 1px solid #5B6575;
                border-top: none;
                border-bottom: none;
            }
        }
    }
}