body,
#root {
	margin: 0;
	height: 100%;
	min-height: 100%;
}

.persons-layout,
.aboutus-layout,
.contact-layout,
.common-layout {
	background-image: url("./images/Blanco.png");
	background-size: contain;
    background-repeat: no-repeat;
}

.fintechs-layout {
    background-image: url("./images/Verde.png");
    background-size: contain;
    background-repeat: no-repeat;
}

.company-layout {
    background-image: url("./images/Negro.png");
    background-size: contain;
    background-repeat: no-repeat;
}

@media (min-width: 992px) {
    .persons-layout,
    .aboutus-layout,
    .contact-layout,
    .common-layout,
    .company-layout {
        background-size: cover;
    }
}
 
@media (min-width: 1200px) {
    .persons-layout,
    .aboutus-layout,
    .contact-layout,
    .common-layout,
    .company-layout {
        background-size: cover;
    }
}

@media (min-width: 1900px) {
    .persons-layout,
    .aboutus-layout,
    .contact-layout,
    .common-layout,
    .company-layout {
        background-size: cover;
    }
}
