$breakpoint-xs: 480px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1900px;

@mixin media($media) {
  @if $media == xs {
    @media (min-width: $breakpoint-xs) {
      @content;
    }
  } @else if $media == sm {
    @media (min-width: $breakpoint-sm) {
      @content;
    }
  } @else if $media == md {
    @media (min-width: $breakpoint-md) {
      @content;
    }
  } @else if $media == lg {
    @media (min-width: $breakpoint-lg) {
      @content;
    }
  } @else if $media == xl {
    @media (min-width: $breakpoint-xl) {
      @content;
    }
  } @else if $media == xxl {
    @media (min-width: $breakpoint-xxl) {
      @content;
    }
  }
}

@mixin backgroundStyle {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@mixin backgroundBody($theme: white) {
  background: $theme;
}