@import "./Fonts.scss";
@import "./Footer.scss";
@import "./Mixins.scss";

*,
::after,
::before {
  box-sizing: border-box;
  padding: 0;
}

body {
    margin: 0;
    padding: 0;
    font-family: "Gilroy-Regular", sans-serif;
    box-sizing: border-box;
    background-position: inherit;
}

ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

h1, h2, h3, p {
    margin: 0;
}

h1, h2 {
    font-family: "Gilroy-Bold", sans-serif;
}

@media (max-width: 1024px) {
    .testDescription{
        font-size: 24px;
    }
}

a {
    text-decoration: none;
    cursor: pointer;
}

strong {
    font-weight: Bold;
}

.content {
    padding: 50px 20px;

    @include media(md) {
        padding: 80px;
    }
    @include media(lg) {
        padding: 80px;
    }
    @include media(xl) {
        padding: 100px;
    }
}

.aling-item-center{
    align-items: center;
}

.is-flex {
    display: flex;
}

.justify-end{
    justify-content: end;
}

.text-left{
    text-align: left;
}

.navbar {
    background: rgba(255, 255, 255, 0.65) !important;
    backdrop-filter: blur(7px);
}

.navbar-nav {
    align-items: center;
}

.offcanvas .navbar-nav .nav-link {
    padding-right: 0rem;
    padding-left: 0rem;
    padding-bottom: 1.5rem;
}

@include media (lg) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
        padding-bottom: 0;
    }

    .navbar-brand {
        margin-right: 5rem;
    }
}

.navbar > .container > .navbar-collapse > .navbar-nav > .nav-link {
    color: #5B6575;
}

.navbar > .container > .navbar-collapse > .navbar-nav > .nav-link.active,
.navbar > .container > .navbar-collapse > .navbar-nav > .nav-link:focus {
    color: #55E6C3;
}

.dark-navbar {
    background: rgba(0, 0, 0, 0.5) !important;
    backdrop-filter: blur(5px);
}

.navbar-toggler {
    border: none;
}

.dark-navbar > .container > .navbar-collapse > .navbar-nav > .nav-link {
    color: #FFFFFF;
}

.navbar > .container > .navbar-collapse > .navbar-nav > .nav-link,
.dark-navbar > .container > .navbar-collapse > .navbar-nav > .nav-link {
    font-family: 'Gilroy-Medium';
    line-height: 21px;
}

.primary-btn {
    background: #55E6C3;
    color: #FFFFFF;
    font-family: 'Gilroy-Bold';
    border: none;
}

.primary-btn:hover {
    background: #22c19c;
    color: #FFFFFF;
    font-family: 'Gilroy-Bold';
    border: none;
}

.primary-btn:disabled {
    background: #1caa89;
    color: #FFFFFF;
    font-family: 'Gilroy-Bold';
    border: none;
}

.primary-btn:active,
.primary-btn:focus,
.primary-btn:active:focus {
    background: #1c8068;
    box-shadow: 0 0 0 0.25rem rgba(49, 253, 185, 0.5);
}

.black-btn {
    background: #000000;
    color: #FFFFFF;
    font-family: 'Gilroy-Bold';
    border: none;
}

.black-btn:hover {
    background: #383838;
    border: none;
}

.black-btn:active,
.black-btn:focus
.black-btn:active:focus {
    background: #000000;
    box-shadow: 0 0 0 0.25rem rgba(24, 24, 24, 0.5);
}

.primary-btn a,
.black-btn a,
a .primary-btn,
a .black-btn
{
    color: #FFFFFF
}

.primary-btn.fint-btn  {
    color: #253632;
}

.primary-btn:disabled,
.fint-btn:disabled {
    background-color: gray;
}


.btn-a {
    width: 100%;
}

.black-btn,
.primary-btn,
.btn-large,
.fint-btn.primary-btn {
    width: 75%;
}

.nav-button {
    display: none;
}

@include media(sm) {
    .btn-a {
        width: 100%;

        .black-btn,
        .primary-btn {
            width: 75%;
        }
    }

    .fint-btn.primary-btn {
        width: 75%;
    }

    .btn-large {
        width: 75%;
    }
}

@include media(md) {
    .btn-a {
        width: inherit;

        .black-btn,
        .primary-btn {
            width: max-content;
        }
    }

    .btn-large {
        width: 10rem !important;
    }

    .fint-btn.primary-btn {
        width: inherit;
    }

    .nav-button {
        display: none;
    }
}

@include media(lg) {
    .btn-a {
        width: inherit;

        .black-btn,
        .primary-btn {
            width: max-content;
        }
    }

    .btn-large {
        width: 10rem !important;
    }

    .fint-btn.primary-btn {
        width: inherit;
    }

    .nav-button {
        display: inline;
    }
}

@include media(xl) {
    .btn-a {
        width: inherit;

        .black-btn,
        .primary-btn {
            width: max-content;
        }
    }

    .btn-large {
        width: 10rem !important;
    }

    .fint-btn.primary-btn {
        width: inherit;
    }

    .nav-button {
        display: inline;
    }
}

.icon-text,
.icon-text-person,
.icon-text-company {
    width: 90%;
    margin: auto;
    display: block;
    justify-content: center;
    box-shadow: 0px 0px 50px rgba(201, 210, 226, 0.5);
    border-radius: 27px;
    
    @include media(sm) { 
        width: 90%;
        margin: auto;
        display: block;
    }

    @include media(md) { 
        width: 100%;
        margin: auto;
        display: block;
    }

    @include media(lg) { 
        width: 100%;
        margin: auto;
        display: flex;
    }

    @include media(xl) { 
        width: 100%;
        margin: auto;
        display: flex;
    }

    &__wrapper{
        text-align: center;
        padding: 30px 20px;

        @include media(md) { 
            padding: 30px;

        }
    
        @include media(sm) { 
            padding: 30px;

        }
    
        @include media(xs) { 
            padding: 30px;

        }

        h3{
            font-family: "Gilroy-bold";
            font-size: 25px;
            line-height: 40px;
            margin: 0;
        }

        p{
            font-family: 'Gilroy-Regular';
            font-size: 18px;
            line-height: 22px;
            margin: 0;
        }
    }
}

.common-layout {
    h1 {
        color: #55E6C3;
    }
    
    h4 {
        font-family: 'Gilroy-Bold';
        color: #000000;
    }
    
    p{
        color: #000000;
    }   
}

#products{
    .title{
        font-size: 24px;
        font-family: 'Gilroy-Bold';
        color: #808080;
    }
    .subTitle{
        font-family: 'Gilroy-Regular';
        font-size: 16px;
        color: #808080        ;
    }
    .more{
        text-decoration: underline;
        font-size: 18px;
        font-family: 'Gilroy-Bold';
    }
    .more:hover{
        text-decoration: underline;
        color: #808080 ;
    }
    .card{
        box-shadow: 0px 0px 50px rgba(201, 210, 226, 0.5);
        border-radius: 20px;
        border: none;
    }
    .cardWidth{
        background-color: #fff;
    }
    .cardBlack{
        background-color: #393939;
    }
}
#privacy,
#legal, #security, #buro {
    h6 {
        font-family: 'Gilroy-Bold';
        color: #000000;
    }

    p {
        text-align: justify;
    }

    p {
        a {
            color: #000000;
        }
        b {
            font-family: 'Gilroy-Bold';
            color: #000000;
        }
    }

    p.back {
        a {
            color: #778191;
        }
    }

    li {
        text-align: justify;
        a {
            color: #000000;
        }
    }
}

#legal, #security {
    ul {
        list-style-type: disc;
        margin-bottom: 1rem;
        padding-left: 2rem;
    }
}

.header-title {
    display: flex; 
    align-items: stretch; 
    justify-content: space-between;
    flex-direction: column;
    text-align: center;

    @include media(sm) { 
        flex-direction: column;
        text-align: center;
    }

    @include media(md) { 
        flex-direction: row;
        text-align: left;
    }

    @include media(lg) { 
        flex-direction: row;
        text-align: left;
    }

    @include media(xl) { 
        flex-direction: row;
        text-align: left;
    }

    @include media(xxl) { 
        flex-direction: row;
        text-align: left;
    }

    .header-title-line {
        height: auto;
        border: 1px solid #55E6C3; 
        margin: 15px 0;

        @include media(sm) { 
            margin: 15px 0;
        }
    
        @include media(md) { 
            margin: 0 15px;
        }
    
        @include media(lg) { 
            margin: 0 15px;
        }
    
        @include media(xl) { 
            margin: 0 15px;
        }
    
        @include media(xxl) { 
            margin: 0 15px;
        }
    }

    .header-title-sub { 
        display: flex; 
        align-items: center;
        justify-content: center;

        @include media(sm) { 
            justify-content: center;
        }
    
        @include media(md) { 
            justify-content: start;
        }
    
        @include media(lg) { 
            justify-content: start;
        }
    
        @include media(xl) { 
            justify-content: start;
        }
    
        @include media(xxl) { 
            justify-content: start;
        }
    }
}

.text-mobile {
    display: block;
}

.text-tablet {
    display: none;
}

.text-web {
    display: none;
}

@include media(md) {
    .text-mobile {
        display: none;
    }

    .text-tablet {
        display: block;
    }

    .text-web {
        display: none;
    }
}

@include media(lg) {
    .text-mobile {
        display: none;
    }

    .text-web {
        display: none;
    }
    
    .text-tablet {
        display: block;
    }
}

@include media(xl) {
    .text-mobile {
        display: none;
    }

    .text-web {
        display: block;
    }

    .text-tablet {
        display: none;
    }
}

@include media(xxl) {
    .text-mobile {
        display: none;
    }

    .text-web {
        display: block;
    } 
    
    .text-tablet {
        display: none;
    }
}

.offcanvas {
    background: rgba(255, 255, 255, 0.90);
    backdrop-filter: blur(7px);
    width: 60%;
    a.nav-link.active {
        color: #55E6C3;
    }

    a.nav-link {
        color: #5B6575;
    }
}

.fintechs,
.company {
    .offcanvas {
        background: rgba(0, 0, 0, 0.5) !important;
        backdrop-filter: blur(5px) !important;
        width: 60%;
        a.nav-link.active {
            color: #55E6C3;
        }
    
        a.nav-link {
            color: #FFFFFF;
        }
    }
}

.offcanvas-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1020;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}

.offcanvas-backdrop.show {
    opacity: .5;
}

.text-wrap {
    width: 100%;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    height: 20px;
}

.color-Primary {
    color: #55E6C3
}

.pointer {
    cursor: pointer;
}