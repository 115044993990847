@font-face{
    font-family: 'Gilroy-Regular';
    src: url(../fonts/Gilroy-Regular.ttf);
    font-weight: normal;

}

@font-face{
    font-family: 'Gilroy-Bold';
    src: url(../fonts/Gilroy-Bold.ttf);
    font-weight: Bold;

}

@font-face{
    font-family: 'Gilroy-Medium';
    src: url(../fonts/Gilroy-Medium.ttf);
    font-weight: Medium;

}

@font-face{
    font-family: 'Gilroy-Light';
    src: url(../fonts/Gilroy-Light.ttf);
    font-weight: Light;
}