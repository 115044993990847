@import "./Fonts.scss";
@import "./Mixins.scss";

body.company,
#root.company {
	@include backgroundBody(#3b3b3b);
}

.company-layout {
    h1 {
        color: #55E6C3;
    }

    h3, p {
        color: #FFFFFF;
        margin-top: 10px;
    }

    h2 {
        color: #FFFFFF;
    }

    h4 {
        font-family: 'Gilroy-Bold';
        color: #FFFFFF;
    }

    .hero-col {
        margin: auto;
        text-align: center;
    
        .text-mobile, .text-web {
            color: #FFFFFF;
        }
        h1 {
            font-size: 50px;
            @include media(md) {
                font-size: 3rem;
            }
    
            @include media(lg) {
                font-size: 4rem;       
            }
            
            @include media(xl) {
                font-size: 4rem;            
            }
    
            @include media(xxl) {
                font-size: 4.5rem;   
            }
        }
        h2 {
            font-size: 24px;
            font-family: 'Gilroy-Bold';
            @include media(md) {
                font-size: 1.5rem;
            }
    
            @include media(lg) {
                font-size: 2rem;          
            }
            
            @include media(xl) {
                font-size: 2rem;           
            }
    
            @include media(xxl) {
                font-size: 2.5rem;           
            }
        }
        h3 {
            font-size: calc(1.3rem + .6vw);
            font-family: 'Gilroy-Bold';
            @include media(md) {
                font-size: 1.5rem;
            }
    
            @include media(lg) {
                font-size: 2rem;          
            }
            
            @include media(xl) {
                font-size: 2rem;           
            }
    
            @include media(xxl) {
                font-size: 2.5rem;           
            }
        }
    
        @include media(md) {
            text-align: left;
        }
    
        @include media(lg) {
            text-align: left;   
        }
        
        @include media(xl) {
            text-align: left; 
        }
    
        @include media(xxl) {
           text-align: left;    
        }
    }
}

.hero-image {
    position: initial;
    width: inherit;
    margin: 0;

    @include media(md) {
        position: relative;
        left: -50px;
        width: 350px;
    }

    @include media(lg) {
        position: relative;
        left: -50px;
        width: 500px;         
    }
    
    @include media(xl) {
        position: relative;
        left: -120px;
        width: 590px;            
    }

    @include media(xxl) {
        position: relative;
        left: -150px;
        width: 650px;          
    }
}
 
@media (min-width: 992px) {
    .company-layout h1 {
        font-size: 3rem;
    }
}
 
@media (min-width: 1200px) {
    .company-layout h1 {
        font-size: 4rem;
    }
}

@media (min-width: 1900px) {
    .company-layout h1 {
        font-size: 4rem;
    }
}

.icon-text-company {
    background-color: #38383A;
    color: #FFFFFF;
    margin-bottom: 3rem;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.5);
    
    &__wrapper{

        h2, h3 {
            font-size: 30px;
            margin-top: 10px;
        }

        p{
            font-size: 17px;
            font-family: "Gilroy-Light";
            margin-top: 10px;

            @include media(md) {
                font-size: 22px;
            }
    
            @include media(lg) {
                font-size: 22.5px;          
            }
            
            @include media(xl) {
                font-size: 22.5px;           
            }
    
            @include media(xxl) {
                font-size: 24px;           
            }
        }

        &:nth-child(2){
            border-left: none;
            border-right: none;
            border-top: 1px solid #FFFFFF;
            border-bottom: 1px solid #FFFFFF;
            
            @include media(sm) {
                border-left: none;
                border-right: none;
                border-top: 1px solid #FFFFFF;
                border-bottom: 1px solid #FFFFFF;
            }
            
            @include media(md) {
                border-left: none;
                border-right: none;
                border-top: 1px solid #FFFFFF;
                border-bottom: 1px solid #FFFFFF;
            }
            
            @include media(lg) {
                border-left: 1px solid #FFFFFF;
                border-right: 1px solid #FFFFFF;
                border-top: none;
                border-bottom: none;   
            }

            @include media(xl) {
                border-left: 1px solid #FFFFFF;
                border-right: 1px solid #FFFFFF;
                border-top: none;
                border-bottom: none;
            }
        }
    }

    img {
       margin-bottom: 20px; 
    }
}