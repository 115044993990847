@import "./Fonts.scss";
@import "./Mixins.scss";

body.persons,
#root.persons {
	@include backgroundBody(white);
}

.financeTitle{
    font-family: 'Flora', sans-serif;
    margin: 0;
    font-size: 34px;
    font-weight: 400;
    color: '#1c4e42' ;
    margin-top: 15px;
}
 .documents{
     color: #000 ;
 }
 .documents:hover{
     color: #55E6C3;
 }
  .react-tabs__tab--selected{ 
    color: rgb(85, 230, 195)!important;
    font-size: 20px;
 
}

 .list-group-item{
        color: #ffffff;
        background: transparent;
        padding: 0.2rem 1rem;
        border: none;
    }
    
.text-image {
    width: 100%;
    background-image: url("../images/legales_bg.jpg") !important;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    justify-content: initial;
    align-items: center;
    height: 360px;
    
    @include media(md) {
        justify-content: center;
    }
}

 