@import "./Fonts.scss";
@import "./Mixins.scss";

body.fintechs,
#root.fintechs {
	@include backgroundBody(#233430);
}

.fintechs-layout {
    h1, h3 {
        color: #55E6C3;
    }

    h1 {
        font-family: 'Gilroy-Bold', sans-serif;
        margin-bottom: 22px;
        text-align: center;

        @include media(md) {
            text-align: center;
        }

        @include media(lg) {
            text-align: left;
        }
        
        @include media(xl) {
            text-align: left;      
        }

        @include media(xxl) {
            text-align: left;
        }
    }

    .icon-text {
        p{
            text-align: center;
        }
    }
    
    h2, h4, p {
        color: #FFFFFF;
        @media screen and (max-width: 768px) {
          font-size: 14px;
        }
    }

    .hero-fint{
        margin: 3rem 0;

        @include media(md) {
            margin: 3rem 0;    
        }

        @include media(lg) {
            margin: 5rem 0;   
        }
        
        @include media(xl) {
            margin: 5rem 0;         
        }

        @include media(xxl) {
            margin: 5rem 0;   
        }
    }

    .col-img {
        text-align: center;
    }

    .hero-fint-img {
        width: 90%;
        @include media(md) {
            width: 90%;
        }

        @include media(lg) {
            width: 100%;
        }
        
        @include media(xl) {
            width: 100%;
        }

        @include media(xxl) {
            width: 100%;
        }
    }

    .hero-col {
        text-align: center;
        display: flex;
        flex-direction: column; 
        justify-content: center;
        align-items: center;
        margin: 0;  

        @include media(md) {
            align-items: flex-start;
            text-align: left;
        }

        @include media(lg) {
            align-items: flex-start;
            text-align: left;    
        }
        
        @include media(xl) {
            align-items: flex-start;
            text-align: left;           
        }

        @include media(xxl) {
            align-items: flex-start; 
            text-align: left;           
        }
    }

    .fintechs-section-3 {
        background-image: url("./../images/bolitas_fintech.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-repeat: no-repeat;
    
        .container {
            display: flex;
            flex-direction: column; 
            justify-content: center;
        }
    
    
        @include media(md){
            background-attachment: fixed;
        }
        @include media(lg){
            background-attachment: fixed;
        }
        @include media(xl){
            background-attachment: fixed;
        }
        @include media(xxl){
            background-attachment: fixed;
        }
    
        h1{
            color: #55E6C3;
        }
    
        p {
            color: #FFFFFF;
        }
    
        &__text {
            p{
                color: #000000;
                font-size: 24px;
                font-family: "Gilroy-Light";
            }
        }
        &__title {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            align-content: center;
        }
        &__subtitle {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            align-content: center;
            // padding-left:70px;
            p{
                color: #55E6C3 !important;
            }
        }
    }

    .form-section {
        padding: 80px 0px;
        background: #0B2F27;
    
        label{
            color: #FFFFFF;
            font-family: 'Gilroy-Light', sans-serif;
        }
    
        h3 {
            color: #FFFFFF;
        }
    
        h2 {
            width: 100%;
            margin: auto;
            color:white;
        }
    
        form{
            input {
                border: none;
            }
            
            a{
                color: #55E6C3;
            }
        }
    }

    .hero-col,
    .fintechs-section-3,
    .form-section {
        h1 {
            font-size: 50px;
            @include media(md) {
                font-size: 3rem;
            }
    
            @include media(lg) {
                font-size: 4rem;          
            }
            
            @include media(xl) {
                font-size: 4rem;           
            }
    
            @include media(xxl) {
                font-size: 4.5rem;           
            }
        }

        h2 {
            font-size: 22px;
            font-family: 'Gilroy-Bold';

            @media screen and (max-width: 767px) {
                text-align: center;
            }
            @include media(md) {
                font-size: 1.5rem;
            }
    
            @include media(lg) {
                font-size: 2rem;          
            }
            
            @include media(xl) {
                font-size: 2rem;           
            }
    
            @include media(xxl) {
                font-size: 2.5rem;           
            }
        }

        h3 {
            font-size: 28px;
        }

        p {
            font-size: 17px;

            @include media(md) {
                font-size: 22px;
            }
    
            @include media(lg) {
                font-size: 22.5px;          
            }
            
            @include media(xl) {
                font-size: 22.5px;           
            }
    
            @include media(xxl) {
                font-size: 24px;           
            }
        }
    }

    .accept-col {
        display: flex;
        flex-direction: column; 
        justify-content: center;
        align-items: center;
        text-align: center;
    
        @include media(md) {
            align-items: flex-start;
            text-align: left;
        }

        @include media(lg) {
            align-items: flex-start;
            text-align: left;            
        }
        
        @include media(xl) {
            align-items: flex-start;
            text-align: left;            
        }

        @include media(xxl) {
            align-items: flex-start;
            text-align: left;            
        }
    }
}

.icon-text {
    background-color: #1E403D !important;
    color: #FFFFFF;
    margin-bottom: 3rem;
    
    &__wrapper{

        h2 {
            font-size: 25px;
            margin-top: 5px;
            @include media(md) {
                font-size: 30px;
                 margin-top: 10px;
            }
            
            @include media(lg) {
                font-size: 30px;
                 margin-top: 10px;
            }

            @include media(xl) {
                font-size: 30px;
                margin-top: 10px;
            }

            @include media(xxl) {
                font-size: 30px;
                 margin-top: 10px;
            }
        } 
        
        h3 {
            font-size: 30px;
            margin-top: 10px;
        }

        p{
            font-size: 17px;
            font-family: "Gilroy-Light";
            margin-top: 10px;

            @include media(md) {
                font-size: 20px;
                 margin-top: 10px;
            }
            
            @include media(lg) {
                font-size: 20px;
                 margin-top: 10px;
            }

            @include media(xl) {
                font-size: 20px;
                margin-top: 10px;
            }

            @include media(xxl) {
                font-size: 25px;
                 margin-top: 10px;
            }
        }

        &:nth-child(2) {
            border-left: none;
            border-right: none;
            border-top: 1px solid #55E6C3;
            border-bottom: 1px solid #55E6C3;
            
            @include media(sm) {
                border-left: none;
                border-right: none;
                border-top: 1px solid #55E6C3;
                border-bottom: 1px solid #55E6C3;
            }
            
            @include media(md) {
                border-left: none;
                border-right: none;
                border-top: 1px solid #55E6C3;
                border-bottom: 1px solid #55E6C3;
            }
            
            @include media(lg) {
                border-left: 1px solid #55E6C3;
                border-right: 1px solid #55E6C3;
                border-top: none;
                border-bottom: none;   
            }

            @include media(xl) {
                border-left: 1px solid #55E6C3;
                border-right: 1px solid #55E6C3;
                border-top: none;
                border-bottom: none;
            }
        }
    }

    img {
       margin-bottom: 20px; 
    }
}

.header-fintechs {
    h1,h5 {
        color: #55E6C3;
    }

    h1{
        @include media(sm) {
            font-size: 3rem;
        }
        
        @include media(md) {
            font-size: 3rem;
        }
        
        @include media(lg) {
            font-size: 4.3rem;
        }

        @include media(xl) {
            font-size: 4.3rem;
        }
    }

    .divider {
        display: flex;
        border-left: 1px solid #55E6C3;
        align-items: center;
    }
}

.fintechs-section-2{
    h1{
        color: #55E6C3 !important;
    }
    .section-image{
        &__subtitle{
            p{
                color: #55E6C3 !important;
            }
        }
        
        &__text{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            align-content: center;
        }

    }
}

$xs : 0px;
$sm : 576px;
$md : 768px;
$lg : 1200px;
$xl : 1400px;

.fintechs-section-4{
    
    background: radial-gradient(58.4% 58.4% at 54.33% 102.65%, #22574F 0%, rgba(35, 90, 81, 0.522855) 47.71%, rgba(37, 93, 84, 0) 100%);

    height: 100vh;
    display: flex;
    // justify-content: center;
    align-items: center;

    height: 126vh;

    @include media(md){
        height: 120vh;

    }


    h1{
        color: #55E6C3 !important;
    }

    &__text {
        display: flex;
        justify-content: center;
        align-items: center;

        p{
            color: #000000;
            font-size: 24px;
            font-family: "Gilroy-Light";
        }
    }
    &__title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
    }
    &__subtitle {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        // padding-left:70px;
        p{
            color: #55E6C3 !important;
        }
    }
}

.icon-text-fint{
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.5);
}