.banner-faq {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 30vh;
}

.accordion-item{
    border: 0;
    border-bottom: 1px solid rgba(0,0,0,.125);
}

.accordion-header{
    margin-bottom: 0;
    button {
        background: white!important;
        color: #666666!important;
        font-family: 'Gilroy-Bold';
    }  
    h2 {
        margin-bottom: 0!important;
    }
}

.priv-layout{
    text-align: justify;
    h4{
        font-size: 22px;
        font-weight: bold;
    }
}