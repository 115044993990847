@import "./Fonts.scss";
@import "./Mixins.scss";

body.persons,
#root.persons {
	@include backgroundBody(white);
}

.aboutus-layout,
.contact-layout {
    h1, h2, h3, h4 {
        font-family: 'Gilroy-Bold';
    }

    h1 {
        color: #55E6C3;
    }
}

.persons-img-div {
    display: none;
    background-attachment: fixed;
    background-image: url('../images/about/about-persons.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 70vh;

    @include media(xxl) {
        display: block;
    }    
}

.persons-img {
    display: block;
    width: 100%;

    @include media(xxl) {
        display: none;
    }
}

ul.contact {
    list-style-type: disc;
    margin-top: 0;
    margin-bottom: 1rem;
    padding-left: 2rem;
}